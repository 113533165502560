.App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 80px;
}

.App-header {
  background-color: #222;
  height: 150px;
  padding: 20px;
  color: white;
}

.App-title {
  font-size: 1.5em;
}

.App-intro {
  font-size: large;
}

.MuiPaper-root {
  color: #ffffff !important;
  background-color: #414141 !important;
}

.MuiPickersDay-root {
  color: #ffffff !important;
  background-color: #414141 !important;
}

.MuiTypography-root {
  color: #ffffff !important;
}
.MuiPickersArrowSwitcher-root {
  fill: #ffffff !important;
}

.MuiTooltip-tooltip {
  background-color: #555555 !important;
  font-size: 16px !important;
}

span {
  color: #ffffff !important;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.react-markdown p {
  font-size: 16px;
}
